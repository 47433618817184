import React from "react"

import { Page } from "../../../components/elements"
import { Col, Row } from "react-bootstrap"
import { DefaultHeader } from "../../../components/elements"
import { PageCenter } from "../../../components/elements/wrappers"

function Member({ title, children }) {
    return (
        <div className={`text-center m-4`} style={{ width: "fit-content" }}>
            <h6>{title}</h6>
            <div>{children}</div>
        </div>
    )
}

export default function Component() {
    return (
        <Page
            title="Advisory Board"
            description="The EGI Advisory Board exists exclusively to advise on the research and educational programs at the Energy & Geoscience Institute. The Board’s function is also to provide advice on current and future research directions, review financial soundness, and provide insights for educational and support related activities as requested by EGI and the University of Utah."
        >
            <PageCenter bg="white">
                <DefaultHeader title="Advisory Board" type="small" />
                <p className="px-4">
                    The EGI Advisory Board exists exclusively to advise on the
                    research and educational programs at the Energy & Geoscience
                    Institute. The Board’s function is also to provide advice on
                    current and future research directions, review financial
                    soundness, and provide insights for educational and support
                    related activities as requested by EGI and the University of
                    Utah.
                </p>
                <hr className="my-5" />
                <p className="mb-4 lead text-center">Advisory Board Members</p>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Member title="Jonathan Craig">
                            Advisory Board Chairman <br />
                            Senior VP – Exploration <br />
                            ENI S.p.A
                        </Member>
                        <Member title="John Baza">
                            Director <br />
                            Utah Department of <br />
                            Natural Resources
                        </Member>
                        <Member title="Melissa Coleman">
                            General Manager <br />
                            Global Exploration <br />
                            ConocoPhillips
                        </Member>
                        <Member title="Greg Hebertson">
                            VP – Global Exploration <br />
                            Murphy Oil
                        </Member>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Member title="Neville Henry">
                            President <br />
                            Winchester
                        </Member>
                        <Member title="Bill Keach">
                            Director <br />
                            Utah Geological Survey
                        </Member>
                        <Member title="Andrew Latham">
                            Vice President, <br />
                            Global Exploration Research <br />
                            Wood MacKenzie
                        </Member>
                        <Member title="Angus McCoss">
                            CTO & Founder <br />
                            dCarbonX Ltd
                        </Member>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Member title="Kevin McLachlan">
                            Senior VP – Exploration <br />
                            Total
                        </Member>
                        <Member title="Jonathan Peachey">
                            VP – Exploration Portfolio & New Business <br />
                            Shell Upstream
                        </Member>
                        <Member title="David W. Pershing">
                            Distinguished Professor Chemical Engineering <br />
                            President Emeritus <br />
                            University of Utah
                        </Member>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Member title="Neil Piggott">
                            VP Exploration <br />
                            Hess
                        </Member>
                        <Member title="Lisa Rebora">
                            Senior Vice President, <br />
                            Exploration Excellence <br />
                            Equinor
                        </Member>
                        <Member title="Luca Rigo de Righi">
                            General Manager, <br />
                            Global New Ventures <br />
                            Chevron
                        </Member>
                    </Col>
                </Row>
            </PageCenter>
        </Page>
    )
}
